<template>
  <v-dialog
    :value="show"
    :max-width="$dialog.small"
    persistent
    no-click-animation
  >
    <v-card>
      <v-card-title>
        <v-icon left color="warning">mdi-alert</v-icon>
        <span class="font-weight-bold">Please Confirm</span>
      </v-card-title>
      <v-card-text>
        <div v-html="message"></div>

        <v-fade-transition>
          <v-form
            v-show="captcha && !deleting"
            @submit.prevent="confirmed()"
            ref="captchaForm"
            class="mt-5 mb-n5"
          >
            <v-text-field
              v-model="captchaField"
              :rules="[()=> textConfirmation == captchaField || 'Invalid input']"
              outlined
            >
              <template v-slot:label>
                Type <strong>"{{ textConfirmation }}"</strong> to confirm.
              </template>
            </v-text-field>
          </v-form>
        </v-fade-transition>

        <div class="mt-5 d-flex gap-7">
          <v-btn
            @click="confirmed()"
            :loading="deleting"
            color="accent"
            class="gradient"
            small
          >Confirm</v-btn>

          <v-btn
            @click="cancel()"
            :disabled="deleting"
            depressed
            small
          >Cancel</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data() {
    return {
      captchaField: null,
    }
  },

  props: {
    show: Boolean,
    message: String,
    deleting: Boolean,
    captcha: Boolean,
    textConfirmation: String,
  },

  methods: {
    confirmed() {
      if (this.captcha) {
        if (this.$refs.captchaForm.validate()) {
          this.$emit('confirmed')
          this.$refs.captchaForm.resetValidation()
          this.captchaField = null
        }
      }
      else {
        this.$emit('confirmed')
      }
    },

    cancel() {
      this.$emit('cancel')
      this.captchaField = null
      this.$refs.captchaForm.resetValidation()
    }
  }
}

</script>
